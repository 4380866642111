import React from "react";
import CardBox from "../../components/marketing/cardBox";

const CardIdx = () => {
  const data = [
    {
      company: "BrandU Avacen",
      name: "John Doe",
      company2: "BrandU Avacen",
      role: "Co-Founder",
      views: 9,
      sends: 28,
    },
    {
      company: "Team Elite",
      name: "Jane Doe",
      company2: "BrandU Avacen",
      role: "Co-Founder",
      views: 300,
      sends: 10,
      img: <img src="/source1.png" alt="profile" />,
    },
    {
      company: "Nirvana Gold",
      name: "Micheal",
      company2: "BrandU Avacen",
      role: "Co-Founder",
      views: 99,
      sends: 12,
    },
    {
      company: "Infinite Solutions",
      name: "Tugsjargal",
      company2: "Infinite Solutions",
      role: "Co-Founder",
      views: 48,
      sends: 233,
    },
  ];
  return (
    <div className="cards-wrap">
      {data.map((el, idx) => (
        <CardBox info={el} image={el.img} />
      ))}
    </div>
  );
};

export default CardIdx;
