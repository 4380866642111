import React, { useEffect, useState } from "react";
import Cardbox from "../../components/marketing/cardBox";
import axios from "../../axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { Dropdown, Input, Menu } from "antd";
import { FaCog, FaList, FaSearch, FaThLarge } from "react-icons/fa";

const MyPages = () => {
  const [cards, setCards] = useState(null);
  const [cardCounts, setcardCounts] = useState({
    maxCards: 100,
    totalCards: 100,
  });
  const [pageSize, setPageSize] = useState(12);
  const [searchVal, setSearchVal] = useState("");
  const [sort, setSort] = useState({ order: "date", order_up: "0" });
  const [sortVisible, setSortVisible] = useState(false);

  const getCards = () => {
    axios
      .post("/api_call", {
        data: JSON.stringify({
          order: sort.order,
          order_up: sort.order_up,
          filter: searchVal.toLowerCase(),
          filter_type: "all",
          page: 1,
          page_size: pageSize,
          more: false,
          folder_type: "folder",
          folder_id: "",
        }),
        service: "/card/list",
        timeout: {},
      })
      .then((res) => {
        let parsedData = JSON.parse(res.data);
        setcardCounts({
          maxCards: parseInt(parsedData.max_card_number),
          totalCards: parseInt(parsedData.total_card),
        });
        console.log(parsedData);
        setCards(parsedData.cards);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* eslint-disable */
  useEffect(() => {
    getCards();
  }, [pageSize, sort, searchVal]);
  /* eslint-enable */

  // const showSizeChange = (current, pageSize) => {
  //   setPageSize(pageSize);
  //   setCurrentPage(current);
  // };

  //block blockoor ni zurah tasalj bgad

  const fetchMoreData = () => {
    setTimeout(() => {
      setPageSize((prev) => prev + 12);
      getCards();
    }, 500);
  };

  const sortMenu = [
    {
      label: "Most Used",
      order: "most_used",
      order_up: "1",
    },
    {
      label: "Most Viewed",
      order: "most_viewed",
      order_up: "1",
    },
    {
      label: "Newest",
      order: "most_recent",
      order_up: "1",
    },
    {
      label: "Oldest",
      order: "most_recent",
      order_up: "0",
    },
  ];

  return (
    <div className="cards-wrap">
      <div className="cards-wrap--menu">
        <div className="active">Recent</div>
        <div>View all</div>
        <div>Purchased</div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
        <div className="cards-wrap--controls">
          <Input
            allowClear
            prefix={
              <FaSearch style={{ marginRight: "10px", color: "#adadad" }} />
            }
            onChange={(e) => setSearchVal(e.target.value)}
            placeholder="Search"
          />
          <div className="cards-wrap--controls--counters">{`${
            cardCounts?.totalCards || 0
          }/${cardCounts?.maxCards || 0}`}</div>
          <div className="cards-wrap--controls--layout active_layout">
            <FaThLarge />
          </div>
          <div className="cards-wrap--controls--layout">
            <FaList />
          </div>
          <Dropdown
            onVisibleChange={() => setSortVisible(!sortVisible)}
            visible={sortVisible}
            trigger={["click"]}
            overlay={
              <Menu>
                <div className="card_sorts">
                  {sortMenu.map((el, idx) => (
                    <div
                      className={
                        sort.order === el.order && sort.order_up === el.order_up
                          ? "card_sorts--active"
                          : "card_sorts--non"
                      }
                      key={idx}
                      onClick={() => {
                        setSort({ order: el.order, order_up: el.order_up });
                        setSortVisible(!sortVisible);
                      }}
                    >
                      {el.label}
                    </div>
                  ))}
                </div>
              </Menu>
            }
          >
            <div className="cards-wrap--controls--cog">
              <FaCog />
            </div>
          </Dropdown>
        </div>
        <InfiniteScroll
          dataLength={cards?.length || 100}
          next={fetchMoreData}
          hasMore={(cards?.length || 0) !== cardCounts?.totalCards}
        >
          {cards && cards.map((el, idx) => <Cardbox key={idx} info={el} />)}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default MyPages;
