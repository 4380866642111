import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import "./App.scss";
import "antd/dist/antd.css";

import Header from "./components/header";
import Sidebar from "./components/sidebar";

import Contacts from "./pages/contacts";
import Marketing from "./pages/marketing";
import Dashboard from "./pages/dashboard";
import Login from "./pages/Login";
import { CookiesProvider } from "react-cookie";
import Loader from "./components/loader";
import { useSelector } from "react-redux";

function App() {
  const [isLogged, setIsLogged] = useState(false);
  let isLoading = useSelector((state) => state.isLoading);

  return (
    <CookiesProvider>
      <Router>
        {isLoading && <Loader />}
        <Route path="/">
          {isLogged ? (
            <div className="App">
              <Sidebar />
              <div>
                <Header />
                <div>
                  <Switch>
                    <Route path="/" exact component={Dashboard} />
                    <Route path="/marketing" component={Marketing} />
                    <Route path="/contacts" component={Contacts} />
                  </Switch>
                </div>
              </div>
            </div>
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route>
          {isLogged ? <Redirect to="/" /> : <Login setIsLogged={setIsLogged} />}
        </Route>
      </Router>
    </CookiesProvider>
  );
}

export default App;
