import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  Input,
  message,
  Popconfirm,
  Tooltip,
  Switch,
} from "antd";
import { useSelector } from "react-redux";

const MarketPhone = ({ data, isSelected, url }) => {
  const user = useSelector((state) => state.user);
  const [scrollOn, setScrollOn] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [useProfile, setUseProfile] = useState(false);
  const myref = useRef();
  const [scrollInterval, setScrollInterval] = useState(null);

  const [formFields, setFormFields] = useState([
    {
      label: "Card Title",
      data: data.card_title,
    },
    {
      label: "First Name",
      data: user.first_name,
    },
    {
      label: "Last Name",
      data: user.last_name,
    },
    {
      label: "Email",
      data: user.email,
    },
    {
      label: "Phone",
      data: user.phone,
    },
    {
      label: "Company Name",
      data: user.company_name,
    },
    {
      label: "Job Title",
      data: user.job_title,
    },
    {
      label: "Address",
      data: user.address,
    },
  ]);
  /* eslint-disable */
  useEffect(() => {
    const h = myref.current.clientHeight;
    let i = 350,
      value = 20;

    const scrollImage = () => {
      myref.current.style.transform = `translateY(-${((i - 350) / h) * 100}%)`;
      myref.current.style.transition = `linear .1s`;
      if (i + value < h) {
        i += value;
      } else {
        i = h;
      }
    };

    if (scrollOn && !scrollInterval) {
      setScrollInterval(setInterval(scrollImage, 100));
    } else {
      clearInterval(scrollInterval);
      setScrollInterval(null);
      myref.current.style.transform = `translateY(0)`;
    }
  }, [scrollOn]);
  /* eslint-enable */

  const handleFormFields = (idx, data) => {
    let tmp = [...formFields];
    tmp[idx].data = data;
    setFormFields(tmp);
  };

  const validateFields = () => {
    let data = formFields.map((e) => e.data);
    if (data[0].length > 0 && data[1].length > 0) {
      message.loading({ content: "Loading... ", key: "success" });
      setTimeout(() => {
        message.success({
          content: "Successfully created! ",
          key: "success",
          duration: 2,
          onClose: () => {
            setModalOpen(!modalOpen);
          },
          onClick: () => {
            setModalOpen(!modalOpen);
          },
        });
        console.log(data);
      }, 1000);
    } else {
      message.error({
        content: "Please fill the all required fields!",
        duration: 2,
      });
    }
  };

  const handleFormWithProfile = (index, value) => {
    let tmp = [...formFields];
    tmp[index].data = value;
    setFormFields(tmp);
  };

  const validateFieldsWithProfile = () => {
    let tmp = formFields.map((el) => el.data);
    if (tmp[0].length > 0 && tmp[1].length > 0) {
      message.loading({ content: "Loading... ", key: "success" });
      setTimeout(() => {
        message.success({
          content: "Successfully created! ",
          key: "success",
          duration: 2,
          onClose: () => {
            setModalOpen(!modalOpen);
          },
          onClick: () => {
            setModalOpen(!modalOpen);
          },
        });
      }, 1000);
      if (useProfile) {
        console.log(tmp);
      } else {
        console.log(formFields[0].data);
      }
    } else {
      message.error({
        content: "Please fill the all required fields!",
        duration: 2,
      });
    }
  };

  const modalRenderer = (
    <Modal
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      footer={
        <Button
          style={{ background: "#3568c9", color: "white" }}
          onClick={
            data.is_standart ? validateFields : validateFieldsWithProfile
          }
        >
          Create{" "}
        </Button>
      }
    >
      <div className="modal_create_new_card">
        <div className="modal_create_new_card--header">Create New Card</div>
        <div className="modal_create_new_card--form">
          {data.is_standart ? (
            formFields.map((el, idx) => (
              <div key={idx} className="modal_create_new_card--form--field">
                <div>
                  {idx < 2 && <span style={{ color: "red" }}>*</span>}
                  {el.label}:
                </div>
                <Input
                  onChange={(e) => handleFormFields(idx, e.target.value)}
                  value={el.data}
                />
              </div>
            ))
          ) : (
            <div>
              <div className="modal_create_new_card--form--field">
                <div>
                  <span style={{ color: "red" }}>*</span> Card Title{" "}
                </div>
                <Input
                  value={formFields[0].data}
                  onChange={(e) => handleFormWithProfile(0, e.target.value)}
                />
              </div>
              <div className="modal_create_new_card--form--profile_switch">
                Use My Profile Info:{" "}
                <Switch onChange={(e) => setUseProfile(e)} />
              </div>
              {useProfile &&
                Object.values(user).map((el, idx) => (
                  <div key={idx} className="modal_create_new_card--form--field">
                    <div>
                      {idx < 1 && <span style={{ color: "red" }}>*</span>}
                      {formFields[idx + 1].label}:
                    </div>
                    <Input
                      onChange={(e) =>
                        handleFormWithProfile(idx + 1, e.target.value)
                      }
                      value={formFields[idx + 1].data}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );

  const copyTemplateID = () => {
    let dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = data.template_id;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    message.success({ content: "Copied!", duration: 2 });
  };

  return (
    <div className={`${isSelected ? "selected" : ""} marketplace-phone`}>
      {data.is_standart === false && (
        <Popconfirm
          placement="topRight"
          okText="Yes"
          cancelText="No"
          title="Are you really want to delete this template?"
          onConfirm={() => window.alert("deleted")}
        >
          <div className="marketplace-phone--x_bttn">X</div>
        </Popconfirm>
      )}

      {data.template_id && (
        <Tooltip placement="topLeft" title="Search Token">
          <div className="template_id" onClick={copyTemplateID}>
            {data.template_id}
          </div>
        </Tooltip>
      )}
      <div
        onClick={() => setModalOpen(!modalOpen)}
        className="marketplace-phone--image"
        onMouseEnter={() => setScrollOn(true)}
        onMouseLeave={() => setScrollOn(false)}
      >
        <div ref={myref}>
          {data.img ? (
            data.img
          ) : (
            <img
              src="https://s3.amazonaws.com/app.elify.com/vbc3/6748/1607863807051-159400533.jpeg"
              alt="scroll"
            />
          )}
        </div>
      </div>
      <div className="marketplace-phone--controls">
        <div className="marketplace-phone--title">
          {data.card_title || "The title is here"}
        </div>
        <div className="marketplace-phone--buttons">
          <Button
            style={{ background: "#3568c9", color: "white" }}
            onClick={() => setModalOpen(!modalOpen)}
          >
            Select
          </Button>
          <Button>
            <a href={url ? url : "https://app.elify.com"} target="blank">
              Preview
            </a>
          </Button>
        </div>
      </div>

      {modalRenderer}
    </div>
  );
};

export default MarketPhone;
