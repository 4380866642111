import React, { useState } from "react";
import CardBox from "../components/marketing/cardBox";
import CampaignModal from "../components/marketing/campaignModal";
import { Button } from "antd";

const App = () => {
  const [state, setState] = useState(false);
  return (
    <div style={{ padding: "30px" }}>
      <CardBox info={{ photo: "/source1.png" }} />
      <CampaignModal visible={state} onCancel={setState} />
      <Button onClick={() => setState(!state)}>test</Button>
    </div>
  );
};

export default App;
