import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Timeline,
  Form,
  Input,
  Radio,
  Checkbox,
  Dropdown,
  TimePicker,
  message,
} from "antd";
import { FaChevronLeft, FaPlusCircle } from "react-icons/fa";
import Axios from "../../axios";
import moment from "moment";
import { useSelector } from "react-redux";

const CampaignModal = ({ visible, id = "99495", onCancel }) => {
  const [formVisible, setFormVisible] = useState(false);
  const [campaigns, setCampaigns] = useState(null);
  const [leadCapture, setLeadCapture] = useState(false);
  const [additionals, setAdditionals] = useState([]);

  const [form] = Form.useForm();
  let account_id = useSelector((state) => state.accountInfo);
  account_id = account_id.profile?.account_id;

  const getCampaigns = () => {
    visible &&
      Axios.post("api_call", {
        data: JSON.stringify({
          order: "date",
          order_up: 1,
          filter: "",
          filter_type: "all",
          more: "start",
          action: "card",
          card_id: id,
          item_id: "",
          item_type: "",
          page: 1,
          page_size: 60,
        }),
        service: "/card/campaign/search",
        timeout: {},
      })
        .then(({ data }) => {
          let parsed = JSON.parse(data);
          setCampaigns(parsed.campaigns);
          console.log(parsed);
        })
        .catch((err) => console.log(err));
  };

  /* eslint-disable */
  useEffect(() => {
    getCampaigns();
  }, [visible]);
  /* eslint-enable */

  const openFormFields = () => {
    setFormVisible(!formVisible);
  };

  const closeModal = () => {
    onCancel && onCancel(false);
    setFormVisible(false);
  };

  const filterData = (data) => {
    let fields = [
      "name",
      "description",
      "is_lead_capture",
      "lead_capture_title",
      "settings_card_view",
      "settings_card_view_email",
      "settings_card_view_push",
      "settings_lead",
      "settings_lead_email",
      "settings_lead_position",
      "settings_lead_push",
      "settings_card_view_date",
      "settings_lead_date",
    ];
    let tmp = {};

    fields.forEach((el) => {
      tmp[el] = data[el];
    });
    tmp.card_id = id;
    tmp.invite_link_id = "";
    tmp.is_survey_included = 0;
    tmp.custom_fields = additionals;
    tmp.settings_card_view_date =
      tmp.settings_card_view_date?.format("HH:mm") || "08:00";
    tmp.settings_lead_date = tmp.settings_lead_date?.format("HH:mm") || "08:00";

    return tmp;
  };

  const getFormData = () => {
    form.validateFields().then((values) => {
      createCampaign(filterData(values));
    });
  };

  const createCampaign = (data) => {
    Axios.post("api_call", {
      data: JSON.stringify(data),
      service: "/card/campaign/add",
      timeout: {},
    })
      .then((res) => {
        message.success("Success!");
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const generateFieldName = () => {
    return `custom_field_${account_id}_${Date.now()}`;
  };

  const addNewField = (el) => {
    let tmp = [...additionals];
    console.log("element", el);
    console.log("tmp", tmp);
    if (
      !(el.field_type === "image" && tmp.some((e) => e.field_type === "image"))
    ) {
      tmp.push({
        field_default_value: "",
        field_name: generateFieldName(), //addNewField
        field_type: el.field_type,
        menuLabel: el.menuLabel,
        field_mandatory: 0,
        field_value_options: el.field_value_options,
      });
      setAdditionals(tmp);
    } else {
      message.warn("Only one image upload is allowed", 3);
    }

    // form.setFieldsValue({ custom_fields: { date: "ASD" } }); //test
  };

  const campaignMenuItems = [
    {
      menuLabel: "Short Text field",
      field_type: "text",
      field_value_options: "short",
    },
    {
      menuLabel: "Long Text field",
      field_type: "text",
      field_value_options: "long",
    },
    { menuLabel: "Radiobox", field_type: "radio" },
    { menuLabel: "Dropdown menu", field_type: "combobox" },
    { menuLabel: "Checkbox", field_type: "check" },
    {
      menuLabel: "Upload Business Card Photo",
      field_type: "image",
      field_value_options: "short",
    },
  ];

  /*
    additionals = [
      {
        "field_default_value":"",
        "field_name":"custom_field_1791_1622081285899",  //addNewField
        "field_type": "text",
      }
    ]
  */

  const campaignMenu = (
    <div className="campaign_drop_menu">
      {campaignMenuItems.map((el, idx) => (
        <div key={idx} onClick={() => addNewField(el)}>
          {el.menuLabel}
        </div>
      ))}
    </div>
  );

  const leadCaptureHandler = (value) => {
    if (value) form.setFieldsValue({ is_lead_capture: "1" });
    else form.setFieldsValue({ is_lead_capture: "0" });
    setLeadCapture(value);
  };

  const setFieldValue = (index, field_key, field_value) => {
    const oldAdditionals = [...additionals];
    oldAdditionals[index][field_key] = field_value;
    setAdditionals(oldAdditionals);
  };

  const pageRenderer = () => {
    if (formVisible)
      return (
        <div className="campaign_form_wrap">
          <Form layout="vertical" form={form}>
            <Form.Item
              label="Name:"
              rules={[{ required: true, message: "Please fill name." }]}
              name="name"
            >
              <Input />
            </Form.Item>
            <Form.Item label="Description:" name="description">
              <Input.TextArea style={{ height: "180px" }} />
            </Form.Item>
            <div className="campaign_notifications">
              <div className="campaign_notifications--title">
                Notification Settings
              </div>
              <NotificationSettings
                fields={{
                  view: "settings_card_view",
                  email: "settings_card_view_email",
                  push: "settings_card_view_push",
                  date: "settings_card_view_date",
                }}
              />
            </div>
            <Form.Item name="is_lead_capture" initialValue="0">
              <Checkbox
                style={{ margin: "20px 0" }}
                onChange={(e) => leadCaptureHandler(e.target.checked)}
              >
                Include Lead Capture
              </Checkbox>
            </Form.Item>
            {leadCapture ? (
              <div className="campaign_notifications">
                <div className="campaign_notifications--title">
                  Lead Capture Appearance
                </div>
                <Form.Item
                  name="settings_lead_position"
                  initialValue="overlay_with_dismiss"
                >
                  <Radio.Group>
                    <Radio value="bottom">Show at Bottom</Radio>
                    <Radio value="overlay_with_dismiss">Optional Popup</Radio>
                    <Radio value="overlay_by_submitting_form">
                      Mandatory Popup
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="Lead Capture Title:"
                  name="lead_capture_title"
                  shouldUpdate
                >
                  <Input />
                </Form.Item>
                <div
                  className="campaign_notifications"
                  style={{ margin: "20px 0" }}
                >
                  <div className="campaign_notifications--title">
                    Mandatory field
                  </div>
                  <Input value="First name" disabled />
                </div>

                {additionals.map((el, index) => (
                  <CampaignField
                    el={el}
                    key={el.field_name}
                    setFieldValue={setFieldValue}
                    index={index}
                    hasOptions={["combobox", "check", "radio"].includes(
                      el.field_type
                    )}
                  />
                ))}
                <Dropdown overlay={campaignMenu} trigger={["click"]}>
                  <div className="field_add_button">
                    <FaPlusCircle style={{ color: "#4dbfa9" }} />
                    Add more fields
                  </div>
                </Dropdown>

                <NotificationSettings
                  fields={{
                    view: "settings_lead",
                    email: "settings_lead_email",
                    push: "settings_lead_push",
                    date: "settings_lead_date",
                  }}
                />
              </div>
            ) : (
              <div style={{ fontSize: " 10px", color: "#808080" }}>
                When viewers fill out Lead Captures, new contact will be created
                under your Contacts.
              </div>
            )}
          </Form>
        </div>
      );
    else {
      if (campaigns)
        return (
          <div className="campaigns_wrap">
            <Timeline>
              {campaigns.map((el, idx) => (
                <Timeline.Item key={idx}>{el.name}</Timeline.Item>
              ))}
            </Timeline>
          </div>
        );
      else return <div className="no_campaigns">There are no campaigns.</div>;
    }
  };
  return (
    <Modal
      onCancel={closeModal}
      visible={visible}
      title={
        <div className="campaign_modal_title">
          {formVisible && (
            <div onClick={() => setFormVisible(false)}>
              <FaChevronLeft />
            </div>
          )}
          Campaign
        </div>
      }
      footer={
        formVisible ? (
          <div>
            <Button onClick={closeModal} type="text">
              Cancel
            </Button>{" "}
            <Button type="primary" onClick={getFormData}>
              Create
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{ color: "rgb(59,122,213)", border: " 0" }}
              onClick={openFormFields}
            >
              + Create New Campaign
            </Button>
            <Button>Cancel</Button>
          </div>
        )
      }
    >
      <div style={{ height: "60vh", overflowY: "auto" }}>{pageRenderer()}</div>
    </Modal>
  );
};

const NotificationSettings = ({ fields }) => {
  const [hasTime, setHasTime] = useState(false);

  const radioHandler = (value) => {
    setHasTime(!hasTime);
  };
  return (
    <div>
      <Form.Item name={fields.view} initialValue="individual">
        <Radio.Group onChange={(e) => radioHandler(e.target.value)}>
          <Radio value="individual">Individual</Radio>
          <Radio value="daily_summary">Daily Summary</Radio>
        </Radio.Group>
      </Form.Item>

      {hasTime && (
        <div className="campaign_time">
          <span>Recieve at</span>
          <Form.Item name={fields.date} initialValue={moment("08:00", "HH:mm")}>
            <TimePicker
              format="HH:mm"
              allowClear
              onChange={(e) => e && console.log(e.format("HH:mm"))}
            />
          </Form.Item>
        </div>
      )}

      <Form.Item
        name={fields.email}
        label="Email Notification"
        initialValue="1"
      >
        <Radio.Group>
          <Radio value="1">On</Radio>
          <Radio value="0">Off</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name={fields.push} label="Push Notification" initialValue="1">
        <Radio.Group>
          <Radio value="1">On</Radio>
          <Radio value="0">Off</Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

const CampaignField = ({ el, setFieldValue, index, hasOptions }) => {
  const [options, setOptions] = useState(
    el.field_value_options ? null : [{ value: "" }]
  );

  const optionHandler = (id, value) => {
    let tmp = [...options];
    tmp[id].value = value;
    setOptions(options);
    setFieldValue(
      index,
      "field_value_options",
      JSON.stringify(options.map((el) => el.value))
    );
  };

  const addOptionField = () => {
    let tmp = [...options];
    tmp.push({ value: "" });
    setOptions(tmp);
  };
  const deleteOptionField = (id) => {
    let tmp = [...options];
    tmp.splice(id, 1);
    setOptions(tmp);
    console.log(options);
  };

  const checkBoxHandler = (value) => {
    value
      ? setFieldValue(index, "field_mandatory", 1)
      : setFieldValue(index, "field_mandatory", 0);
  };

  return (
    <div className="campaign_notifications">
      <div className="campaign_notifications--title">{el.menuLabel}</div>

      <Form.Item
        rules={[{ required: true, message: "Please fill this title." }]}
        name={"custom_title_" + index}
      >
        <Input
          placeholder="Title "
          value={el?.field_id ?? ""}
          onChange={(e) => setFieldValue(index, "field_id", e.target.value)}
        />
      </Form.Item>
      <Form.Item name="field_mandatory">
        <Checkbox onChange={(e) => checkBoxHandler(e.target.checked)}>
          Mandatory
        </Checkbox>
      </Form.Item>

      {options &&
        options.map((option, idx) => (
          <Form.Item
            name={"custom_title_" + index + "_option_" + idx}
            rules={[{ required: true, message: "Please fill this option." }]}
            key={idx}
          >
            <Input
              placeholder={el.menuLabel + " option *"}
              value={option.value}
              onChange={(e) => optionHandler(idx, e.target.value)}
              suffix={
                idx === 0 ? (
                  <div style={{ height: "30px" }} />
                ) : (
                  <div
                    className="campaign_button grey"
                    onClick={() => deleteOptionField(idx)}
                  >
                    -
                  </div>
                )
              }
            />
          </Form.Item>
        ))}
      {hasOptions && (
        <div className="option_button_wrap">
          <div
            className="option_button_wrap--button campaign_button"
            onClick={addOptionField}
          >
            +
          </div>
        </div>
      )}
    </div>
  );
};
export default CampaignModal;
