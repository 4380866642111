import React, { useState } from "react";
import {
  FaHome,
  FaBullhorn,
  FaBell,
  FaComments,
  FaHistory,
  FaBroadcastTower,
  FaLightbulb,
  FaQuestion,
  FaChevronLeft,
} from "react-icons/fa";

const Sidebar = () => {
  const [scaled, setScaled] = useState(true);

  const menus = [
    <FaHome />,
    <FaBullhorn />,
    <FaBell />,
    <FaComments />,
    <FaHistory />,
    <FaBroadcastTower />,
    <FaLightbulb />,
  ];

  const textMenu = [
    "home",
    "announcements",
    "notifications",
    "chatter",
    "activity",
    "channels",
    "discover",
  ];

  return (
    <div className="sidebar">
      <div className="first-bar">
        <div className="sidebar-profile">
          <img src="/source1.png" alt="source" />
        </div>
        <div className="icons">
          {menus.map((el, idx) => (
            <div key={idx}>{el}</div>
          ))}
        </div>
        <div className="question">
          <FaQuestion />
        </div>
      </div>
      <div className={`${scaled ? "sidebar-hidden" : ""} float-container`}>
        <div className="second-bar">
          <div className="main-name">Micheal Richards</div>
          <div className="icon-texts">
            {textMenu.map((el, idx) => (
              <div key={idx}>{el}</div>
            ))}
          </div>
          <div className="utils-text">
            <div>help desk</div>
            <div>log out</div>
          </div>
        </div>
        <div
          className={`${scaled ? "chevron-rotate" : ""} third-bar`}
          onClick={() => setScaled(!scaled)}
        >
          <FaChevronLeft />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
