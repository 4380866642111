import React from "react";
import QRCode from "qrcode.react";
import { Modal, Button, message } from "antd";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";

const LinkModal = ({ visible, id, onCancel }) => {
  const link = "https://app.elify.com/vbc/" + id;

  const copyHandler = () => {
    const put = document.createElement("textarea");
    put.value = link;
    document.body.appendChild(put);
    put.select();
    document.execCommand("copy");
    message.success("Copied!", 4);
    document.body.removeChild(put);
  };

  return (
    <Modal
      visible={visible}
      title="Untrackable Link"
      onCancel={() => onCancel(false)}
      footer={<Button onClick={() => onCancel(false)}>Close</Button>}
    >
      <div className="link-wrapper">
        <div className="link-wrapper--small">Tracking Link</div>
        <div className="link-wrapper--url">{link}</div>
        <div className="link-wrapper--button_wrap">
          <Button onClick={copyHandler}>Copy Link</Button>
        </div>
        <div className="link-wrapper--qr">
          <QRCode value={link} style={{ height: "160px", width: "160px" }} />
        </div>
        <div className="link-wrapper--socials">
          <div className="link-wrapper--socials--title">Share to social</div>
          <a
            href={"http://www.facebook.com/sharer.php?u=" + link}
            target="blank"
          >
            <div style={{ background: "#3b5999" }}>
              <FaFacebook /> Facebook
            </div>
          </a>
          <a href={"https://twitter.com/share?url=" + link} target="blank">
            <div style={{ background: "#01bbf6" }}>
              <FaTwitter /> Twitter
            </div>
          </a>
          <a
            href={
              "http://www.linkedin.com/shareArticle?mini=true&amp;url=" + link
            }
            target="blank"
          >
            <div style={{ background: "#136f9b" }}>
              <FaLinkedin /> LinkedIn
            </div>
          </a>
        </div>
      </div>
    </Modal>
  );
};
export default LinkModal;
