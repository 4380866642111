import React from "react";
import { Input, Button, Form, message } from "antd";
import axios from "../axios";
import { useDispatch } from "react-redux";

const Login = ({ setIsLogged }) => {
  const dispatch = useDispatch();
  const login = (data) => {
    dispatch({ type: "LOADER" });
    axios
      .post("api_login", {
        data: JSON.stringify(data),
        service: "/api_login",
        timeout: {},
      })
      .then((res) => {
        console.log("login", res);
        let response = JSON.parse(res.data);
        if (response.success) {
          message.success("Success!", 3);
          setIsLogged(true);
          dispatch({ type: "LOADER" });
        } else {
          message.error(response.description, 3);
          dispatch({ type: "LOADER" });
        }
      })
      .catch((err) => console.log("error : ", err));
  };

  return (
    <div className="login_container">
      <Form layout="vertical" onFinish={login}>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Please input your email!" }]}
          initialValue="tugsjargal@infinite.mn"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: "Please input your password!" }]}
          initialValue="Tugsuu123"
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item>
          <div className="login_container--buttons">
            <Button htmlType="submit" type="primary">
              Sign In
            </Button>
            <Button>Create New Account</Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export default Login;
