import React from "react";
import MyPages from "./marketing_locals/myPages";
import CardIndx from "./marketing_locals/cardIndex";
import MyContent from "./marketing_locals/myContent";
import Marketplace from "./marketing_locals/marketPlace";
import { Route } from "react-router-dom";

const Marketing = () => {
  let path = document.location.pathname;
  let now = path.split("/");

  return (
    <div className="page">
      <div className="divider">{now[now.length - 1]}</div>
      <div>
        <Route path="/marketing/my-pages" component={MyPages} />
        <Route path="/marketing/my-content" component={MyContent} />
        <Route path="/marketing/card-index" component={CardIndx} />
        <Route path="/marketing/marketplace" component={Marketplace} />
      </div>
    </div>
  );
};

export default Marketing;
