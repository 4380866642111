import React, { useEffect } from "react";

import InfoBox from "../components/infoBox";
import { Button } from "antd";
import Axios from "../axios";
import { useDispatch, useSelector } from "react-redux";

const Dashboard = () => {
  const dispatch = useDispatch();

  let info = useSelector((state) => state.accountInfo);

  /* eslint-disable */
  useEffect(() => {
    const getAccInfo = () => {
      Axios.post("api_call", {
        data: "{}",
        service: "/account/status",
        timeout: {},
      })
        .then(({ data }) => {
          let parsed = JSON.parse(data);
          console.log("account info", parsed);
          dispatch({ type: "ACC_INFO", info: parsed });
        })
        .catch((err) => console.log(err));
    };

    getAccInfo();
  }, []);
  /* eslint-enable */

  const data = [
    {
      value1: 230,
      info1: "Total Connections",
      value2: 15,
      info2: "New Connections",
      color: "#6ebdf1",
    },
    {
      value1: 230,
      info1: "Page Views Today",
      value2: 1254,
      info2: "Total Page Views",
      color: "#6dcfb6",
    },
    {
      value1: 24,
      info1: "Pages Created",
      value2: 20,
      info2: "Pages Shared",
      color: "#e7b05c",
    },
  ];

  return (
    <div className="page">
      <div className="divider">Dashboard</div>
      {info?.profile?.account_id}
      <div className="items-wrapper">
        {data.map((el, idx) => (
          <InfoBox data={el} key={idx} />
        ))}
      </div>
      <div className="divider">Announcements</div>
      <div className="divider">Notifications</div>
      <Button>test</Button>
    </div>
  );
};

export default Dashboard;
