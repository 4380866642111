import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";

const ContentBox = ({ data }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <div className="contentBox">
      <div
        className="contentBox--image"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {data.img ? data.img : <img src="/shuffle-logo.png" alt="logo" />}
        {hovered && (
          <div className="contentBox--image--edit">
            <FaRegEdit />
          </div>
        )}
      </div>
      <div className="contentBox--info">
        <span>{data.name}</span>
        <span>{data.date}</span>
      </div>
    </div>
  );
};

export default ContentBox;
