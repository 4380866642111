import React, { useState } from "react";
import { Input, Menu, Select } from "antd";
// import { FaDesktop, FaMobileAlt } from "react-icons/fa";
import MarketPhone from "../../components/marketing/market_phone";

const Marketplace = () => {
  const [activeIdx, setActiveIdx] = useState(0);
  const [keyValue, setKeyValue] = useState("");

  const [data, setData] = useState([
    {
      card_title: "Bakery",
      is_standart: true,
      img: (
        <img
          src="https://s3.amazonaws.com/app.elify.com/vbc3/6748/1612168203617-368470020.jpeg"
          alt="scroll"
        />
      ),
    },
    {
      card_title: "ColorStreet",
      is_standart: false,
      img: (
        <img
          src="https://s3.amazonaws.com/app.elify.com/vbctemplate_preview_v2/97cs.jpg?v=1.0.1"
          alt="scroll"
        />
      ),
      template_id: "ColorStreet1",
    },
    {
      card_title: "BrandU Avacen",
      is_standart: true,
      img: (
        <img
          src="https://s3.amazonaws.com/app.elify.com/vbc3/6748/1613026150696-156091446_thumb.jpeg"
          alt="scroll"
        />
      ),
    },
    {
      card_title: "Team Elite",
      is_standart: false,
      img: (
        <img
          src="https://s3.amazonaws.com/app.elify.com/vbc3/6748/1614308538772-125408366.jpeg"
          alt="scroll"
        />
      ),
      template_id: "TeamEliteCopyProTraders",
    },
  ]);

  const { Option } = Select;
  const { SubMenu } = Menu;
  const Item = Menu.Item;
  const menus = [
    {
      main: "NEWLY ADDED",
    },
    {
      main: "BEST SELLERS",
    },
    {
      main: "ELIFY TEMPLATES",
      sub: [
        { main: "DESIGNER", sub: ["OPTION1", "OPTION2"] },
        { main: "PRICE RANGE", sub: ["OPTION3", "OPTION4"] },
        { main: "BUSINESS & SERVICES", sub: ["OPTION5", "OPTIO6"] },
        { main: "ECOMMERCE", sub: ["OPTION7", "OPTION8"] },
        { main: "CREATIVE", sub: ["OPTION9", "OPTION10"] },
        { main: "COMMUNITY", sub: ["OPTION11", "OPTION12"] },
      ],
    },
  ];

  const sortHandler = (type) => {
    let tmp = [...data];
    switch (type) {
      case "A-Z": {
        tmp.sort((a, b) =>
          a.card_title > b.card_title ? 1 : a.card_title < b.card_title ? -1 : 0
        );
        break;
      }
      case "Z-A":
        tmp.sort((a, b) =>
          a.card_title < b.card_title ? 1 : a.card_title > b.card_title ? -1 : 0
        );
        break;
      default:
        window.alert(type);
    }

    setData(tmp);
  };

  const searchHandler = (value) => {
    setKeyValue(value.toLowerCase());
  };

  return (
    <div className="cards-wrap">
      <div className="cards-wrap--menu">
        <Input.Search
          placeholder="Search"
          allowClear
          enterButton
          onSearch={(e) => searchHandler(e)}
        />
        <div style={{ color: "#ababab", fontSize: "13px", marginTop: "20px" }}>
          Sort by:
        </div>
        <Select
          defaultValue="Newest"
          onChange={(e) => sortHandler(e)}
          style={{ padding: 0, marginBottom: "10px", width: "100%" }}
        >
          <Option value="Newest">Newest</Option>
          <Option value="Oldest">Oldest</Option>
          <Option value="A-Z">A-Z</Option>
          <Option value="Z-A">Z-A</Option>
        </Select>
        {menus.map((el, idx) => (
          <div
            className={`${
              idx === activeIdx ? "selected-menu" : ""
            } market-menu-main`}
            key={idx}
            onClick={() => setActiveIdx(idx)}
          >
            {el.main}
          </div>
        ))}
        {activeIdx === 2 && (
          <Menu
            title={menus[2].main}
            mode="inline"
            defaultSelectedKeys={["OPTION1"]}
          >
            {menus[2].sub.map((el, idx) => (
              <SubMenu title={el.main} key={idx}>
                {el.sub.map((item, ind) => (
                  <Item key={item}>{item}</Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        )}
      </div>
      <div className="marketplace-container">
        {/* <div className="marketplace-container--orentation">
          <div className="disabled">
            <FaDesktop />{" "}
          </div>{" "}
          <div className="active">
            <FaMobileAlt />
          </div>
        </div> */}
        <div className="marketplace-container--wrapper">
          {data
            .filter((item) => item.card_title.toLowerCase().includes(keyValue))
            .map((el, idx) => (
              <MarketPhone data={el} idx={idx} key={idx} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Marketplace;
