import React from "react"

const InfoBox = ({data})=>{
    return(
        <div className="infoBox" style={{background: data.color}}>
            <div className="infoBox--item">
                <span>

                {data.value1}
                </span>
                <span>
                    {data.info1}
                </span>
            </div>
            <div className="infoBox--item">
                <span>
                    {data.value2}
                </span>
                <span>
                    {data.info2}
                </span>
            </div>
        </div>
    )
}
export default InfoBox