import React, { useRef, useState } from "react";
import { Dropdown, Menu, Modal, Button, Input, Checkbox, message } from "antd";
import {
  FaRegEye,
  FaRegPaperPlane,
  FaLink,
  FaPen,
  FaRegBell,
  FaRegChartBar,
  FaRegCopy,
  FaBullhorn,
  FaFacebookSquare,
  FaRegEdit,
  FaTrash,
  FaSearch,
  FaSort,
  FaChevronLeft,
  FaChevronUp,
  FaChevronDown,
  FaChevronRight,
  FaFacebook,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Axios from "../../axios";
import ActivityModal from "./activityModal";
import LinkModal from "./linkModal";
import CampaignModal from "./campaignModal";

const CardBox = ({ info, image }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contact, setContact] = useState(false);
  const [stage, setStage] = useState("default");
  const [imageLoaded, setImageLoaded] = useState("");
  const [cardActivities, setCardActivities] = useState(null);
  const imageRef = useRef();
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const [campaignModalVisible, setCampaignModalVisible] = useState(false);

  const [optionsOpen, setOptionsOpen] = useState(false),
    [data, setData] = useState();
  const [checked, setChecked] = useState(0);
  const [formData, setFormData] = useState({
    first: "",
    last: "",
    tags: [],
    phone: "",
    email: "",
    address: "",
    isMale: true,
    company: "",
    position: "",
    website: "",
    fb: "",
    tw: "",
    ln: "",
    upload: "",
  });

  let cardTitle = useSelector((state) => state.cardTitle);
  const dispatch = useDispatch();

  const testurl = "https://testingLink.com";

  const menuItems = [
    {
      elem: "Preview",
      icon: <FaRegEye />,
      func: () => window.open(info.untrackable_link, "blank"),
    },
    {
      elem: "Share Card",
      icon: <FaRegPaperPlane />,
      func: () => setContact(!contact),
    },
    {
      elem: "Untrackable Link",
      icon: <FaLink />,
      func: () => setLinkModalVisible(!linkModalVisible),
    },
    {
      elem: "Edit",
      icon: <FaPen />,
    },
    {
      elem: "Activity",
      icon: <FaRegBell />,
      func: () => getActivities(),
    },
    {
      elem: "Stats",
      icon: <FaRegChartBar />,
    },
    {
      elem: "Clone Card",
      icon: <FaRegCopy />,
    },
    {
      elem: "Campaigns",
      icon: <FaBullhorn />,
      func: () => setCampaignModalVisible(!campaignModalVisible),
    },
    {
      elem: "Facebook Pixel",
      icon: <FaFacebookSquare />,
    },
    {
      elem: "Intros",
      icon: <FaRegEdit />,
    },
    {
      elem: "Delete",
      icon: <FaTrash />,
    },
  ];

  const menu = (
    <Menu>
      <div className="cardbox-menu">
        {menuItems.map((item, idx) => (
          <div
            key={idx}
            className="cardbox-menu--item"
            onClick={() => {
              setOptionsOpen(!optionsOpen);
              item.func && item.func();
            }}
          >
            {item.icon}
            {item.elem}
          </div>
        ))}
      </div>
    </Menu>
  );

  const create = () => {
    console.log(formData);
  };

  const backHandler = () => {
    switch (stage) {
      case "add_new": {
        setStage("default");
        dispatch({ type: "CHANGE_TITLE", payload: "Select Contact" });
        break;
      }
      case "user_selected": {
        setStage("default");
        dispatch({ type: "CHANGE_TITLE", payload: "Select Contact" });
        setData();
        break;
      }
      case "user_selected_with":
      case "user_selected_without": {
        dispatch({ type: "CHANGE_TITLE", payload: "Card Share" });
        checked > 0 && setChecked(0);
        setStage("user_selected");
        break;
      }
      case "user_selected_show":
      case "user_selected_email":
      case "user_selected_shuffle": {
        dispatch({ type: "CHANGE_TITLE", payload: "Share Type" });
        checked > 0 && setChecked(0);
        setStage("user_selected_with");
        break;
      }
      default:
        console.log("");
    }
    // if (data) {
    //   setData();
    // }
  };

  const pageRenderer = () => {
    switch (stage) {
      case "default": {
        return <DefaultPage setData={setData} setStage={setStage} />;
      }
      case "add_new": {
        return <AddNewContact setFormData={setFormData} formData={formData} />;
      }
      case "user_selected":
      case "user_selected_with":
      case "user_selected_without":
      case "user_selected_show":
      case "user_selected_email":
      case "user_selected_shuffle": {
        return (
          <CaptureSelector
            setStage={setStage}
            setContact={setContact}
            stage={stage}
            data={data}
            setCheckedBox={setChecked}
            setRealStage={setStage}
            setData={setData}
          />
        );
      }
      default:
        return <div>Fix it!</div>;
    }
  };

  const shareHandler = () => {};
  const shareWithShuffle = () => {
    setStage("user_selected_shuffle");
    setData();
  };
  const copyLink = () => {
    const put = document.createElement("textarea");
    put.value = testurl;
    document.body.appendChild(put);
    put.select();
    document.execCommand("copy");
    message.success("Copied!");
    document.body.removeChild(put);
  };

  const emailCustomize = () => {
    setStage("user_selected_email");
  };

  const buttonsRelatedCheckbox = () => {
    switch (checked) {
      case 1:
        return (
          <Button
            onClick={shareWithShuffle}
            style={{ background: "#3b7ad5", color: "white" }}
          >
            Select
          </Button>
        );
      case 2:
        return (
          <Button
            onClick={() => {
              emailCustomize();
              dispatch({ type: "CHANGE_TITLE", payload: "Link Share" });
            }}
            style={{ background: "#3b7ad5", color: "white" }}
          >
            Select
          </Button>
        );
      case 3:
        return (
          <Button
            onClick={() => {
              dispatch({ type: "CHANGE_TITLE", payload: "Link Share" });
              setStage("user_selected_show");
              copyLink();
            }}
            style={{ background: "#3b7ad5", color: "white" }}
          >
            Show Link
          </Button>
        );
      default:
        return (
          <Button
            onClick={shareHandler}
            style={{ background: "#3b7ad5", color: "white" }}
          >
            Share
          </Button>
        );
    }
  };

  const footerRenderer = () => {
    if (stage === "add_new")
      return (
        <Button
          style={{ background: "#3b7ad5", color: "white", border: "none" }}
          onClick={create}
        >
          Create
        </Button>
      );
    if (data)
      if (checked > 0)
        return (
          <div>
            <Button onClick={() => setContact(!contact)}>Cancel</Button>
            {buttonsRelatedCheckbox()}
          </div>
        );
      else if (stage === "user_selected_without") {
        return (
          <div className="campaign-buttons">
            <Button>+ Create New Campaign</Button>
            <Button onClick={() => setContact(!contact)}>Cancel</Button>
          </div>
        );
      } else
        return (
          <div>
            <Button onClick={() => setContact(!contact)}>Cancel</Button>
          </div>
        );
    else return null;
  };

  const imageHandler = () => {
    if (imageRef.current.clientHeight >= imageRef.current.clientWidth)
      setImageLoaded("h");
    else setImageLoaded("w");
  };

  const getActivities = () => {
    Axios.post("api_call", {
      data: JSON.stringify({
        order: "date",
        order_up: 1,
        filter: "",
        filter_type: "all",
        more: "start",
        action: "card",
        card_id: info.card_id,
        page: 1,
        page_size: 10,
      }),
      service: "/activity/search",
      timeout: {},
    })
      .then((res) => {
        console.log(JSON.parse(res.data));
        setCardActivities(JSON.parse(res.data).activity);
        setIsOpen(true);
      })
      .catch((err) => console.log(err));
  };

  const deleteActivity = (id) => {
    Axios.post("api_call", {
      data: JSON.stringify({ activity_id: id }),
      service: "/activity/delete",
      timeout: {},
    })
      .then((res) => {
        message.success("Success!", 4);
        getActivities();
      })
      .catch((err) => {
        message.error("Error occured.", 4);
      });
  };

  return (
    <div className="card-box">
      <Modal
        visible={contact}
        onCancel={() => setContact(false)}
        footer={footerRenderer()}
      >
        <div className="modal-header">
          {(stage !== "default" || data) && (
            <FaChevronLeft
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={backHandler}
            />
          )}
          {cardTitle}
        </div>
        {pageRenderer()}
      </Modal>

      <LinkModal
        visible={linkModalVisible}
        onCancel={setLinkModalVisible}
        id={info.url}
      />
      <CampaignModal
        visible={campaignModalVisible}
        onCancel={setCampaignModalVisible}
        id={info.card_id}
      />

      <ActivityModal
        visible={isOpen}
        onCancel={setIsOpen}
        activities={cardActivities}
        dotAction={deleteActivity}
      />
      {/* <Modal
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={<Button onClick={() => setIsOpen(false)}>Cancel</Button>}
      >
        <div className="modal-header">d asd</div>
      </Modal> */}
      <div className="card-box--top">
        <div className="share" onClick={() => setContact(!contact)}>
          Share <FaRegPaperPlane />
        </div>
        <div className="profile">
          <img
            onClick={() => window.open(info.untrackable_link, "blank")}
            className={imageLoaded === "h" ? "widthh" : "heightt"}
            ref={imageRef}
            src={
              info.photo
                ? imageLoaded === ""
                  ? "/shuffle-logo.png"
                  : info.photo
                : "/shuffle-logo.png"
            }
            alt="profile"
            onLoad={imageHandler}
          />
        </div>
        <Dropdown
          onVisibleChange={() => setOptionsOpen(!optionsOpen)}
          overlay={menu}
          visible={optionsOpen}
          trigger={["click"]}
          placement="bottomRight"
        >
          <div className="dots" onClick={() => setOptionsOpen(!optionsOpen)}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Dropdown>
      </div>
      <div className="info" onClick={getActivities}>
        <span style={{ fontSize: "12px", marginBottom: "6px" }}>
          {info.card_title || "Card name"}
        </span>
        <span style={{ marginBottom: "6px" }} className="name">
          {info.first_name + " " + info.last_name || "John Doe"}
        </span>
        <span className="company">{info.company_name || "Company name"}</span>
        <span style={{ fontSize: "12px" }}>
          {info.job_title || "Co-Founder"}
        </span>
      </div>
      <div className="buttons" onClick={() => setIsOpen(!isOpen)}>
        <div className="buttons--button">
          <span>Sends</span>
          <span>{info.total_sent || 0}</span>
        </div>
        <div className="buttons--button">
          <span>Total Views</span>
          <span>{info.total_opened || 0}</span>
        </div>
      </div>
    </div>
  );
};

const UserItem = ({ data, image, setData, setStage }) => {
  const dispatch = useDispatch();
  return (
    <div
      className="userItem"
      onClick={() => {
        setStage("user_selected");
        setData && setData(data);
        dispatch({ type: "CHANGE_TITLE", payload: "Card Share" });
      }}
    >
      {image ? (
        <div className="userItem--img avatar">{image}</div>
      ) : (
        <div className="userItem--placeholder avatar">
          {data.first.slice(0, 1)}
          {data.last.slice(0, 1)}
        </div>
      )}
      <div className="userItem--info">
        <span>
          {data.first} {"  "} {data.last}
        </span>
        <span>{data.email}</span>
      </div>
    </div>
  );
};

const CaptureSelector = ({
  data,
  setCheckedBox,
  setRealStage,
  stage,
  setContact,
  setStage,
}) => {
  const [selected, setSelected] = useState(0);
  const [checkedOption, setCheckedOption] = useState("standart");
  const dispatch = useDispatch();

  let defaulturl = "https://testingLink.com";

  const socialRenderer = () => {
    switch (selected) {
      case 4:
        return "Facebook";
      case 5:
        return "Twitter";
      default:
        return "LinkedIn";
    }
  };

  const copyHandler = () => {
    const put = document.createElement("textarea");
    put.value = defaulturl;
    document.body.appendChild(put);
    put.select();
    document.execCommand("copy");
    message.success("Copied!");
    document.body.removeChild(put);
  };

  const pageRenderer = () => {
    const checks = [
      "Send via Shuffle",
      "Send via Email",
      "Show Link",
      "Facebook",
      "Twitter",
      "LinkedIn",
    ];

    const introStyle = {
      borderBottom: "1px solid #acacac",
      padding: "10px",
      fontSize: "12px",
      lineHeight: "1",
      margin: "15px 0",
    };

    switch (stage) {
      case "user_selected":
        return (
          <div className="capture-menu">
            <div
              onClick={() => {
                setRealStage("user_selected_with");
                dispatch({ type: "CHANGE_TITLE", payload: "Share Type" });
              }}
            >
              Without Lead Capture <FaChevronRight />
            </div>
            <div
              onClick={() => {
                setRealStage("user_selected_without");
                dispatch({
                  type: "CHANGE_TITLE",
                  payload: "Campagins With Lead Capture",
                });
              }}
            >
              With Lead Capture <FaChevronRight />
            </div>
          </div>
        );
      case "user_selected_with":
        return (
          <div className="shareTypes">
            {checks.map((el, idx) => (
              <div key={idx}>
                {idx === 3 && (
                  <div className="user">
                    {data && `Share on ${data.first + " " + data.last}'s`}
                  </div>
                )}
                <Checkbox
                  checked={selected === idx + 1}
                  onChange={() => {
                    setSelected(idx + 1);
                    setCheckedBox && setCheckedBox(idx + 1);
                  }}
                >
                  {el}
                </Checkbox>
              </div>
            ))}
            {selected > 3 && (
              <div className="notice">
                When you click on <b>Share</b> button, you will be automatically
                directed to{" "}
                <b>
                  {data &&
                    `${data.first + " " + data.last}'s ${socialRenderer()}`}
                </b>{" "}
                page, and trackable link will be copied to your <b>clipboard</b>
                .
              </div>
            )}
          </div>
        );
      case "user_selected_without":
        return (
          <div style={{ padding: "40px", textAlign: "center" }}>
            There are no campaigns.
          </div>
        );
      case "user_selected_show":
        return (
          <div className="link-wrapper">
            <div className="link-wrapper--small">Tracking Link</div>
            <div className="link-wrapper--url">{defaulturl}</div>
            <div className="link-wrapper--button_wrap">
              <Button onClick={copyHandler}>Copy Link</Button>
            </div>
            <div className="link-wrapper--qr">QR here</div>
            <div className="link-wrapper--socials">
              <div className="link-wrapper--socials--title">
                Share to social
              </div>
              <a href="https://facebook.com" target="blank">
                <div style={{ background: "#3b5999" }}>
                  <FaFacebook /> Facebook
                </div>
              </a>
              <a href="https://twitter.com" target="blank">
                <div style={{ background: "#01bbf6" }}>
                  <FaTwitter /> Twitter
                </div>
              </a>
              <a href="https://linkedin.com" target="blank">
                <div style={{ background: "#136f9b" }}>
                  <FaLinkedin /> LinkedIn
                </div>
              </a>
            </div>
          </div>
        );
      case "user_selected_email":
        return (
          <div className="link-wrapper">
            <div className="link-wrapper--small">Tracking Link</div>
            <div className="link-wrapper--url">{defaulturl}</div>
            <div className="link-wrapper--button_wrap">
              <Button onClick={copyHandler}>Copy Link</Button>
            </div>
            <div className="link-wrapper--checks">
              <Checkbox
                checked={checkedOption === "standart"}
                onChange={() => setCheckedOption("standart")}
              >
                Standart
              </Checkbox>
              {checkedOption === "standart" && (
                <div style={introStyle}>
                  Hi, Tugsjargal Batsukh has sent you their "Shuffle Card".
                  Click{" "}
                  <a href="https://app.elify.com" target="blank">
                    here
                  </a>{" "}
                  to see it.
                </div>
              )}
              <Checkbox
                checked={checkedOption === "intro"}
                onChange={() => setCheckedOption("intro")}
              >
                Use saved intro
              </Checkbox>
              {checkedOption === "intro" && (
                <div>
                  <div style={introStyle}>No selected intro.</div>{" "}
                  <div className="link-wrapper--button_wrap">
                    <Button onClick={() => {}}>Choose Intro</Button>
                  </div>
                </div>
              )}
              <Checkbox
                checked={checkedOption === "custom"}
                onChange={() => setCheckedOption("custom")}
              >
                Customize
              </Checkbox>
              {checkedOption === "custom" && (
                <div className="custom_intro">
                  <Input.TextArea maxLength={150} showCount />
                  <div className="input_url">{defaulturl}</div>
                </div>
              )}
            </div>
          </div>
        );
      case "user_selected_shuffle":
        return (
          <div className="congrats">
            <div className="congrats--title">Congratulations!</div>
            <div className="congrats--shared">
              You successfully shared your card with
            </div>
            <div className="congrats--person">Tugsjargal Batsukh</div>
            <div className="congrats--type">Shared by : Shuffle</div>
            <div className="congrats--reminder">
              Schedule Follow-Up Reminder
            </div>
            <div
              className="congrats--ok"
              onClick={() => {
                setContact(false);
                setStage("default");
              }}
            >
              OK
            </div>
          </div>
        );
      default:
        console.log("");
    }
  };

  return <div>{pageRenderer()}</div>;
};

const DefaultPage = ({ setData, setStage }) => {
  const [searchVal, setSearchVal] = useState("");
  const dispatch = useDispatch();

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>data >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const [testUsers, setTestUsers] = useState([
    {
      first: "Chinbat",
      last: "Purev",
      email: "chinbat@infinite.mn",
      image: <img src="/shuffle-logo.png" alt="logo" />,
    },
    {
      first: "Tugsjargal",
      last: "Batsukh",
      email: "tugsjargal@infinite.mn",
    },
    {
      first: "John",
      last: "Doe",
      email: "johndoe@gmail.com",
    },
    {
      first: "Delgersaikhan",
      last: "Altanzul",
      email: "delgersaikhan@infinite.mn",
    },
    {
      first: "Chinbat",
      last: "Purev",
      email: "chinbat@infinite.mn",
    },
    {
      first: "Tugsjargal",
      last: "",
      email: "tugsjargal@infinite.mn",
    },
    {
      first: "John",
      last: "Doe",
      email: "johndoe@gmail.com",
    },
    {
      first: "Delgersaikhan",
      last: "Altanzul",
      email: "delgersaikhan@infinite.mn",
    },
  ]);

  const sortMenu = ["Last view", "Overdue", "Most Recent", "A-Z", "Z-A"];

  const sorts = (
    <Menu>
      <div className="sorts">
        {sortMenu.map((el, idx) => (
          <div key={idx} onClick={() => HandleSorts(idx)}>
            {el}
          </div>
        ))}
      </div>
    </Menu>
  );

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>functions >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const sortA_Z = () => {
    let tmp = [...testUsers];
    tmp.sort((a, b) => (a.first > b.first ? 1 : a.first < b.first ? -1 : 0));
    setTestUsers(tmp);
  };

  const sortZ_A = () => {
    let tmp = [...testUsers];
    tmp.sort((a, b) => (a.first < b.first ? 1 : a.first > b.first ? -1 : 0));
    setTestUsers(tmp);
  };

  const HandleSorts = (index) => {
    switch (index) {
      case 3:
        sortA_Z();
        break;
      case 4:
        sortZ_A();
        break;
      default:
        console.log("default");
    }
  };

  const next = () => {
    setStage("add_new");
    dispatch({ type: "CHANGE_TITLE", payload: "Create New Contact" });
  };

  return (
    <div>
      <div className="finder">
        <Input
          prefix={<FaSearch style={{ color: "#cfcfcf" }} />}
          onChange={(e) => setSearchVal(e.target.value)}
          value={searchVal}
        />
        <div className="put-button" onClick={next}>
          +
        </div>
        <Dropdown trigger={["click"]} overlay={sorts} placement="bottomRight">
          <div className="put-button">
            <FaSort />
          </div>
        </Dropdown>
      </div>
      <div className="users-wrap">
        {testUsers
          .filter((user) =>
            user.first.toLowerCase().match(searchVal.toLowerCase())
          )
          .map((el, idx) => (
            <UserItem
              setStage={setStage}
              data={el}
              image={el.image && el.image}
              key={idx}
              setData={setData}
            />
          ))}
      </div>
    </div>
  );
};

const AddNewContact = ({ formData, setFormData }) => {
  const [tagVal, setTagVal] = useState("");
  const [advanced, setAdvanced] = useState(false);

  const tagHandler = (value) => {
    let arr = formData.tags;
    arr.push(value);
    setFormData({ ...formData, tags: arr });
    setTagVal("");
  };

  const tagRemove = (value) => {
    let arr = formData.tags;
    let idx = arr.indexOf(value);
    arr.splice(idx, 1);
    setFormData({ ...formData, tags: arr });
  };

  return (
    <div className="newContact">
      <div className="form-image">
        <div className="upload-preview">
          {formData.upload ? (
            <img src={formData.upload} alt="uploaded" />
          ) : (
            <div className="placeholder"></div>
          )}
        </div>
        <div className="file-uploader">
          <Button>Upload picture</Button>
          <input
            type="file"
            accept="image/png, image/jpeg"
            onChange={(e) =>
              setFormData({
                ...formData,
                upload: URL.createObjectURL(e.target.files[0]),
              })
            }
          />
        </div>
      </div>
      <div className="two-items">
        <Input
          placeholder="First Name"
          onChange={(e) => setFormData({ ...formData, first: e.target.value })}
        />
        <Input
          placeholder="Last Name"
          onChange={(e) => setFormData({ ...formData, last: e.target.value })}
        />
      </div>
      <div
        className="tags-wrapper"
        style={{ marginTop: formData.tags.length > 0 && "20px" }}
      >
        {formData.tags.map((el, idx) => (
          <div key={idx} className="tag-item">
            {el}
            <span onClick={() => tagRemove(el)}>X</span>
          </div>
        ))}
      </div>
      <Input
        placeholder="Tags"
        onPressEnter={(e) => {
          tagHandler(e.target.value);
        }}
        onChange={(e) => setTagVal(e.target.value)}
        value={tagVal}
      />
      <div className="tag-reminder">
        Search for a Tag, or type a name and click Enter to create one.
      </div>
      <Input
        placeholder="Phone Number"
        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
      />
      <Input
        placeholder="Email"
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
      />
      <Input
        placeholder="Address"
        onChange={(e) => setFormData({ ...formData, address: e.target.value })}
      />
      <div className="advanced">
        <div onClick={() => setAdvanced(!advanced)}>
          Show Advanced {advanced ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      {advanced && (
        <div>
          <div className="gender">
            <span className="gender--title">Gender</span>
            <Checkbox
              checked={formData.isMale}
              onChange={(e) =>
                setFormData({ ...formData, isMale: e.target.checked })
              }
            >
              {" "}
              Male
            </Checkbox>
            <Checkbox
              checked={!formData.isMale}
              onChange={(e) =>
                setFormData({ ...formData, isMale: !e.target.checked })
              }
            >
              {" "}
              Female
            </Checkbox>
          </div>
          <Input
            placeholder="Company"
            onChange={(e) =>
              setFormData({ ...formData, company: e.target.value })
            }
          />
          <Input
            placeholder="Position"
            onChange={(e) =>
              setFormData({ ...formData, position: e.target.value })
            }
          />
          <Input
            placeholder="Web site"
            onChange={(e) =>
              setFormData({ ...formData, website: e.target.value })
            }
          />
          <Input
            placeholder="Facebook"
            onChange={(e) => setFormData({ ...formData, fb: e.target.value })}
          />
          <Input
            placeholder="Twitter"
            onChange={(e) => setFormData({ ...formData, tw: e.target.value })}
          />
          <Input
            placeholder="Linkedin"
            onChange={(e) => setFormData({ ...formData, ln: e.target.value })}
          />
        </div>
      )}
    </div>
  );
};

export default CardBox;
