import React, { useState } from "react";
import { FaUpload, FaCamera } from "react-icons/fa";
import ContentBox from "../../components/marketing/contentBox";
import { Modal, Button } from "antd";

const MyContent = () => {
  const [contents, setContents] = useState([
    {
      name: "profileimage.png",
      date: "2020-06-19",
      img: <img src="/source1.png" alt="profile" />,
    },
    {
      name: "background.png",
      date: "2020-11-19",
      img: <img src="/source2.png" alt="profile" />,
    },
  ]);
  const [modalVisible, setModalVisible] = useState(false);

  const contentUpload = (file) => {
    let imgUrl = URL.createObjectURL(file);
    let tmp = [...contents];
    tmp.push({
      name: file.name,
      date: file.lastModified,
      img: <img src={imgUrl} alt="file" />,
    });
    setContents(tmp);
    setModalVisible(!modalVisible);
  };

  return (
    <div className="cards-wrap">
      <div className="cards-wrap--menu">
        <div className="active">Recent</div>
        <div>View all</div>
        <Modal
          visible={modalVisible}
          onCancel={() => setModalVisible(!modalVisible)}
          footer={
            <Button onClick={() => setModalVisible(!modalVisible)}>
              Cancel
            </Button>
          }
        >
          <div className="addContent">
            <div className="addContent--title">Add new Content</div>
            <div className="addContent--uploader">
              <div className="addContent--uploader--camera">
                <FaCamera />
              </div>
              <input
                type="file"
                accept="image/png, image/jpg"
                onChange={(e) =>
                  e.target.files[0] && contentUpload(e.target.files[0])
                }
              />
            </div>
            <div className="types">Accept types : png, jpg</div>
          </div>
        </Modal>

        <div onClick={() => setModalVisible(!modalVisible)} className="upload">
          <FaUpload /> Upload
        </div>
      </div>
      <div className="cards-wrap--container myContent-wrap">
        {contents.map((el, idx) => (
          <ContentBox data={el} key={idx} />
        ))}
      </div>
    </div>
  );
};

export default MyContent;
