import React from "react";
import { FaCircleNotch } from "react-icons/fa";

const Loader = () => {
  return (
    <div className="loader">
      <FaCircleNotch />
    </div>
  );
};
export default Loader;
