const inititalState = {
  marketing_page: "my pages",
  cardTitle: "Select Contact",
  user: {
    first_name: "Ankhbayar",
    last_name: "Tse",
    email: "ankhbayar@infinite.mn",
    phone: "+976 99180513",
    company_name: "Infinite Solutions LLC",
    job_title: "Head of R&D",
    address: "Infinite Solutions LLC Twin tower",
  },
  isLoading: false,
  accountInfo: {},
};

const RootReducer = (state = inititalState, action) => {
  switch (action.type) {
    case "MARKETING_PAGE":
      return { ...state, marketing_page: action.payload };
    case "CHANGE_TITLE":
      return { ...state, cardTitle: action.payload };
    case "LOADER":
      return { ...state, isLoading: !state.isLoading };
    case "ACC_INFO":
      return { ...state, accountInfo: action.info };
    default:
      return state;
  }
};

export default RootReducer;
