import axios from "axios";

const Axios = axios.create({
  baseURL: "https://app.elify.com/",
  withCredentials: true,
});

// Axios.interceptors.request.use((config) => {
//   console.log("req", config);
//   return config;
// });

// Axios.interceptors.response.use((response) => {
//   console.log("res", response);
//   return response;
// });

export default Axios;
