import React from "react";
import { Modal, Timeline, Button, Dropdown } from "antd";
import {
  FaTrashAlt,
  FaEye,
  FaIdCard,
  FaLink,
  FaBullhorn,
} from "react-icons/fa";

const ActivityModal = ({ visible, onCancel, activities, dotAction }) => {
  const dotRenderer = (act_code) => {
    switch (act_code) {
      case "vbc_link":
        return (
          <div className="timeline_dot_wrap">
            <div className="timeline_dot" style={{ background: "#fec107" }}>
              <FaLink />
            </div>
          </div>
        );
      case "vbc_view_u_user":
        return (
          <div className="timeline_dot_wrap">
            <div className="timeline_dot" style={{ background: "#4cb050" }}>
              <FaEye />
            </div>
          </div>
        );
      case "card_campaign_add":
      case "card_campaign_edit":
      case "card_campaign_delete":
        return (
          <div className="timeline_dot_wrap">
            <div className="timeline_dot" style={{ background: "#9C28B2" }}>
              <FaBullhorn />
            </div>
          </div>
        );

      default:
        return (
          <div className="timeline_dot_wrap">
            <div className="timeline_dot" style={{ background: "#1fc1de" }}>
              <FaIdCard />
            </div>
          </div>
        );
    }
  };
  return (
    <Modal
      visible={visible}
      onCancel={() => onCancel(false)}
      title="Card Activity"
      footer={<Button onClick={() => onCancel(false)}>Cancel</Button>}
      // style={{ height: "80vh", overflowY: "scroll" }}
    >
      <div className="timelines">
        <Timeline>
          {activities &&
            activities.map((el, idx) => (
              <Timeline.Item dot={dotRenderer(el.activity_code)}>
                <div key={idx + "a"} className="timeline_item">
                  <div className="timeline_item--description">
                    {el.description?.replace(/<.+?>/gi, "")}
                  </div>
                  <div className="timeline_item--date">{el.create_date}</div>
                  <Dropdown
                    placement="bottomRight"
                    trigger={["click"]}
                    overlay={
                      <div
                        className="timeline_item--delete"
                        onClick={() => dotAction(el.activity_id)}
                      >
                        <div>
                          <FaTrashAlt /> Delete
                        </div>
                      </div>
                    }
                  >
                    <div className="timeline_item--dots">
                      <div />
                      <div />
                      <div />
                    </div>
                  </Dropdown>
                </div>
              </Timeline.Item>
            ))}
        </Timeline>
        {/* <link type="card">ad</link> */}
      </div>
    </Modal>
  );
};

export default ActivityModal;
