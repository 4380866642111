import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { useHistory } from "react-router-dom";

const Header = () => {
  const [page, setPage] = useState("dashboard"),
    [menuOpen, setMenuOpen] = useState(false);
  const [active, setActive] = useState("my-pages");

  const history = useHistory();

  useEffect(() => {
    history.listen((location) => {
      let path = location.pathname;
      path = path.split("/");
      path = path[path.length - 1];

      setActive(path);
    });
  }, [history]);

  const menu = (
    <Menu>
      <div className="marketing-menu">
        <Link to="/marketing/my-pages">
          <div className={active === "my-pages" ? "active" : ""}>My Pages</div>
        </Link>
        <Link to="/marketing/my-content">
          <div className={active === "my-content" ? "active" : ""}>
            My Content
          </div>
        </Link>
        <Link to="/marketing/card-index">
          <div className={active === "card-index" ? "active" : ""}>
            Card Index
          </div>
        </Link>
        <Link to="/marketing/marketplace">
          <div className={active === "marketplace" ? "active" : ""}>
            Marketplace
          </div>
        </Link>
      </div>
    </Menu>
  );

  return (
    <div className="header">
      <Dropdown overlay={menuOverlay} trigger={["click"]}>
        <div
          className={menuOpen ? "burger burger-active" : "burger"}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Dropdown>
      <div className="header--items">
        <Link
          to="/"
          className={page === "dashboard" ? "active" : undefined}
          onClick={() => setPage("dashboard")}
        >
          Dashboard
        </Link>
        <Link
          to="/contacts"
          className={page === "contacts" ? "active" : undefined}
          onClick={() => setPage("contacts")}
        >
          {" "}
          Contacts
        </Link>
        <Dropdown trigger={["hover"]} overlay={menu}>
          <Link
            className={page === "marketing" ? "active" : undefined}
            onClick={() => setPage("marketing")}
          >
            Marketing
          </Link>
        </Dropdown>
      </div>
      <Link to="/" style={{ textDecoration: "none", color: "black" }}>
        <div className="logo-container">
          <img src="/shuffle-logo.svg" alt="logo" />
          <div>Shuffle</div>
        </div>
      </Link>
    </div>
  );
};

const menuOverlay = () => {
  return (
    <div className="menu_overlay">
      <Link to="/">Dashboard</Link>
      <Link to="/contacts">Contacts</Link>
      <Link to="/marketing/my-pages">Marketing</Link>
    </div>
  );
};
export default Header;
